import { PostPinResponse } from "@/web-client/api";
import { FC, ReactNode, useMemo, useState } from "react";
import { UsePinWithBalloonResponse } from "@/features/pin/hooks/usePinWithBalloon";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  size,
  useFloating,
} from "@floating-ui/react";
import useFloatingPlacementForPin from "@/features/pin/hooks/useFloatingPlacementForPin";
import { flushSync } from "react-dom";

interface Props {
  pin: PostPinResponse;
  pinElement: JSX.Element;
  balloonElement: JSX.Element;
  response: UsePinWithBalloonResponse;

  portaiId?: string;
}

const Offset = 16;

const PinWithBalloon: FC<Props> = ({
  pin,
  pinElement,
  balloonElement,
  response,
  portaiId,
}): JSX.Element => {
  const {
    showBalloon,
    mouseOverHandler,
    mouseLeaveHandler,
    touchStartHandler,
    cancelHideBalloonTimer,
  } = response;

  const left = useMemo(() => pin.position.x * 100, [pin.position.x]);
  const top = useMemo(() => pin.position.y * 100, [pin.position.y]);
  const [maxWidth, setMaxWidth] = useState(0);

  const { refs, floatingStyles } = useFloating({
    placement: useFloatingPlacementForPin(pin.position.x, pin.position.y),
    middleware: [
      offset(16),
      flip(),
      size({
        apply({ availableWidth }) {
          flushSync(() => {
            // availableWidthが大きくなってしまった時に、バルーンもそれに合わせて大きくなりすぎてしまうので最大幅240として制限
            setMaxWidth(availableWidth);
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const Balloon = useMemo<ReactNode>(() => {
    return (
      <div
        ref={refs.setFloating}
        className="absolute z-10 pointer-events-none"
        style={{
          ...floatingStyles,
          // バルーンの最大幅は320pxとする（それ以上あるとかえって見づらいため
          maxWidth: Math.min(maxWidth - Offset, 320),
        }}
        onMouseEnter={cancelHideBalloonTimer}
      >
        {balloonElement}
      </div>
    );
  }, [floatingStyles, maxWidth, cancelHideBalloonTimer, balloonElement, refs]);

  return (
    <>
      <div
        ref={refs.setReference}
        className="absolute pointer-events-auto"
        style={{
          left: `${left}%`,
          top: `${top}%`,
        }}
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        onTouchStart={touchStartHandler}
      >
        {pinElement}
      </div>

      {showBalloon && (
        <>
          {portaiId ? (
            <FloatingPortal id={portaiId}>{Balloon}</FloatingPortal>
          ) : (
            <> {Balloon}</>
          )}
        </>
      )}
    </>
  );
};

export default PinWithBalloon;
