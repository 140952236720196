import { FC } from "react";
import IconAdd from "@/assets/imgs/svg/icon-add.svg";
import AddListBtnWrapper, {
  AddListBtnWrapperProps,
} from "@/features/list/components/AddListBtnWrapper";

const MinimumAddListBtn: FC<AddListBtnWrapperProps> = ({
  selectedItem,
  kind,
  style,
}) => {
  return (
    <AddListBtnWrapper kind={kind} selectedItem={selectedItem} style={style}>
      <span
        className="block border-body-primary rounded-xs p-4 bg-white text-primary shadow-default hover:shadow-none transition duration-200"
        title={"リストに追加"}
      >
        <IconAdd width={20} height={20} />
      </span>
    </AddListBtnWrapper>
  );
};

export default MinimumAddListBtn;
