import useDisclosure from "@/hooks/useDisclosure";
import { useCallback, useRef } from "react";

export interface UsePinWithBalloonResponse {
  mouseOverHandler: VoidFunction;
  mouseLeaveHandler: VoidFunction;
  touchStartHandler: VoidFunction;
  cancelHideBalloonTimer: () => void;
  showBalloon: boolean;
  hideBalloon: (interval: number) => void;
}

const usePinWithBalloon = (): UsePinWithBalloonResponse => {
  const { openHandler, closeHandler, toggle, isOpen } = useDisclosure(false);
  const refLeaveTimer = useRef(-1);

  const cancelHideBalloonTimer = useCallback(() => {
    clearTimeout(refLeaveTimer.current);
  }, [refLeaveTimer]);

  // ピンにマウスオーバーしたとき
  const mouseOverHandler = useCallback(() => {
    cancelHideBalloonTimer();
    openHandler();
  }, [openHandler, cancelHideBalloonTimer]);

  const hide = useCallback(
    (interval: number) => {
      cancelHideBalloonTimer();

      refLeaveTimer.current = window.setTimeout(() => {
        closeHandler();
      }, interval);
    },
    [refLeaveTimer, cancelHideBalloonTimer, closeHandler],
  );

  // ピンから吹き出しからマウスリーブしたとき
  const mouseLeaveHandler = useCallback(() => {
    hide(500);
  }, [hide]);

  const touchStartHandler = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    mouseOverHandler,
    mouseLeaveHandler,
    touchStartHandler,
    cancelHideBalloonTimer,
    showBalloon: isOpen,
    hideBalloon: hide,
  };
};
export default usePinWithBalloon;
