import { PostPinResponse } from "@/web-client/api";
import { FC, useCallback, useEffect } from "react";
import ProductVariationPin from "./ProductVariationPin";
import usePinWithBalloon from "@/features/pin/hooks/usePinWithBalloon";
import PinWithBalloon from "@/features/pin/components/PinWithBalloon";
import useAnalytics from "@/hooks/useAnalytics";
import dynamic from "next/dynamic";
import useDevice from "@/hooks/useDevice";

const DynamicPinBalloon = dynamic(
  () => import("@/features/pin/components/PinBalloon"),
);
const DynamicProductVariaiotnBalloonItem = dynamic(
  () => import("@/features/pin/components/ProductVariationBalloonItem"),
);

interface Props {
  pin: PostPinResponse;

  showBalloon?: boolean;
  portalId?: string;
}

const ProductVariationPinWithBalloon: FC<Props> = ({
  pin,
  showBalloon = false,
  portalId,
}): JSX.Element => {
  const { isSp } = useDevice();
  const { selectContent } = useAnalytics(
    pin.product_variation.product.maker_id,
  );
  const response = usePinWithBalloon();
  const { touchStartHandler, hideBalloon } = response;

  const clickHandler = useCallback(() => {
    selectContent("product_pin", `PROD_${pin.product_variation.product_id}`, {
      relational_item_id: `POST_${pin.post_id}`,
    });
  }, [pin, selectContent]);

  // showBalloonがtrueのとき、マウスオーバーでなくてもバルーンを表示する
  useEffect(() => {
    showBalloon ? touchStartHandler() : hideBalloon(0);
  }, [showBalloon, touchStartHandler, hideBalloon]);

  return (
    <PinWithBalloon
      response={response}
      pin={pin}
      pinElement={
        <ProductVariationPin
          productVariation={pin.product_variation}
          onClick={clickHandler}
          onTouchStart={response.touchStartHandler}
        />
      }
      balloonElement={
        <DynamicPinBalloon
          onClick={clickHandler}
          onMouseEnter={response.mouseOverHandler}
          onMouseLeave={response.mouseLeaveHandler}
          style={{ width: isSp ? "unset" : 200 }}
        >
          <DynamicProductVariaiotnBalloonItem
            productVariation={pin.product_variation}
          />
        </DynamicPinBalloon>
      }
      portaiId={portalId}
    />
  );
};

export default ProductVariationPinWithBalloon;
