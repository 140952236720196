import { PostPinResponse } from "@/web-client/api";
import usePinWithBalloon from "@/features/pin/hooks/usePinWithBalloon";
import { FC, useEffect, useMemo } from "react";

import PinWithBalloon from "@/features/pin/components/PinWithBalloon";
import dynamic from "next/dynamic";
import Link from "next/link";
import NotePin from "@/features/pin/components/NotePin";
const DynamicPinBalloon = dynamic(
  () => import("@/features/pin/components/PinBalloon"),
);

interface Props {
  pin: PostPinResponse;

  showBalloon?: boolean;
  portalId?: string;
}

const NotePinWithBalloon: FC<Props> = ({ pin, showBalloon, portalId }) => {
  const response = usePinWithBalloon();
  const makerName = useMemo<string>(() => {
    return pin.note_pin.maker?.name || pin.note_pin.maker_text;
  }, [pin]);
  const displayLink = useMemo<boolean>(
    () => pin.note_pin.maker && pin.note_pin.maker.is_contracted,
    [pin],
  );
  const { touchStartHandler, hideBalloon } = response;

  useEffect(() => {
    showBalloon ? touchStartHandler() : hideBalloon(0);
  }, [showBalloon, touchStartHandler, hideBalloon]);

  return (
    <PinWithBalloon
      response={response}
      pin={pin}
      pinElement={
        <NotePin
          onTouchStart={response.touchStartHandler}
          notePin={pin.note_pin}
        />
      }
      balloonElement={
        <DynamicPinBalloon
          onMouseEnter={response.mouseOverHandler}
          onMouseLeave={response.mouseLeaveHandler}
        >
          <div className="flex flex-col overflow-hidden space-y-4">
            <h2 className="font-bold w-full text-sm">{pin.note_pin.text}</h2>
            <ul className="text-xs font-bold">
              {makerName && (
                <li>
                  メーカー:
                  {displayLink ? (
                    <Link
                      href={`/makers/${pin.note_pin.maker.id}`}
                      className="text-link"
                    >
                      {makerName}
                    </Link>
                  ) : (
                    <>{makerName}</>
                  )}
                </li>
              )}
            </ul>
          </div>
        </DynamicPinBalloon>
      }
      portaiId={portalId}
    />
  );
};

export default NotePinWithBalloon;
